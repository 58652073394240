import { ScOptin, ScModal, ScCreatePassword } from "@pch/surffers-components";
import { createApp, ref } from "vue";
import { useCreatePassword } from "../../apps/use/create-password";
import { POST_CONFIG } from "../../common/helpers";

let resolveFn = null;
let cpDone = new Promise((resolve) => {
  resolveFn = resolve;
});

export function optinInit() {

  const optinContainer = document.querySelector(".optin-container");
  if (optinContainer) {
    const optinApp = createApp({
      setup() {
        const showOptinCpLb = ref(false);


        function cpClose(reload) {
          if (reload) {
            window.location.reload();
          } else {
            showOptinCpLb.value = false;
            resolveFn();
      
          }
        }

        const cp = useCreatePassword(
          cpClose,
          window.PCHUSER,
          0,
          PCH?.modals?.createPassText,
          PCH?.surveys?.noPassData?.apiUri,
          false
        );

        const payload = {
          subscribe: "1",
          oat: PCHUSER.oat,
        };

        const showStep2 = ref(false);

        async function optinSubmit() {
          console.log("Optin form submitted", POST_CONFIG);

          if (PCH?.surveys?.optins?.registration_url) {
            window.location.href = PCH.surveys.optins.registration_url;
          } else if (PCH?.surveys?.optins?.subscription_api) {
            const response = await fetch(
              PCH?.surveys?.optins?.subscription_api,
              {
                ...POST_CONFIG,
                body: JSON.stringify(payload),
              }
            )
              .then((response) =>
                response.ok ? response.json() : Promise.reject(response)
              )
              .then(async (json) => {
                console.log("success", json);
                if (json.data.noPassword?.noPassOptinCargo) {
                  cp.cpUpdatePayload(
                    "noPassOptinCargo",
                    json.data.noPassword.noPassOptinCargo
                  );
                  cp.cpUpdateText(
                    "tokens",
                    parseInt(json.data.noPassword.noPassTokens)
                  );
                  await openPasswordDialog();
                }

                showStep2.value = true;
                utag.link({"tealium_event":"pchsurvey_new_mailable"}, null, [PCHGA4TagId]);

                if (json.data?.unsMessage) {
                  PCHUniNav.controller.fetchAndUpdateTokenBalanceDisplay();
                  PCHUniNav.controller.showMessage(json.data.unsMessage);
                }
              })
              .catch((err) => {
                console.warn("error:", err);
              });
            return response;
          } else {
            console.warn("no api for optin");
          }
        }

        function optinComplete() {
          //no password user
          optinContainer.classList.add("optin-complete");
          if (window?.PCHUSER?.type === 1) {
            window.location.reload();
          }
        }

        async function openPasswordDialog() {
          if (window?.PCHUSER?.type !== 1) return Promise.resolve();

          showOptinCpLb.value = true;

          return await cpDone;
        }


        return {
          optinSubmit,
          showStep2,
          optinComplete,
          cpClose,
          cpDone,
          showOptinCpLb,
          ...cp,
        };
      },
      components: {
        "sc-optin": ScOptin,
        "sc-modal": ScModal,
        "sc-create-password": ScCreatePassword,
      },
    });
    optinApp.mount(".optin-container");
  }
}
