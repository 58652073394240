import { clickOutsideCloses } from "./helpers";
import { redirect } from '../../common/helpers';

let redirectInProgress = false;

/**
 * 
 * @param {HTMLDialogElement} dialog the dialog element
 */
export default function onsiteMessageModalInit(dialog) {

    const closeButton = dialog.querySelector('#onsite-messaging_close');

    const ctaButton = dialog.querySelector('button.onsite-messaging__button--cta'); //TODO: accommodate multiple CTAs

    const extraCloseButton = dialog.querySelector('[data-close]');

    closeButton.addEventListener('click', () => dialog.close());

    ctaButton.addEventListener('click', ()=> {
        const ctaURL = new URL(ctaButton.dataset.href);

        if(ctaURL.hostname !== window.location.hostname) {
            if(!redirectInProgress) {
                redirectInProgress = true;
                redirect(ctaButton.parentElement, ctaURL.href);
            }
        } else {
            window.location = ctaURL;
        }
        PCHGA.trackEvent(`surveytab`, 'takeanothersurvey', 'invalidmid');
    })

    if(extraCloseButton) extraCloseButton.addEventListener('click', () => dialog.close());

    clickOutsideCloses(dialog);

};