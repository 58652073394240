import { ref, computed } from 'vue';
import { POST_CONFIG, tmplHelper } from '../../common/helpers.js';

/**
 * 
 * @param {PCHUser} user The PCHUSER object
 * @param {Number} noPassBadgeTokens The number of tokens to award for creating a password
 * @param {Object} createPassText Several blocks of text to fill the create password dialog
 * @returns 
 */
function useCreatePassword(
    cpClose,
    user = window.PCHUSER, 
    noPassBadgeTokens = 0, // might become known later 
    createPassText = {}, 
    api = PCH?.surveys?.noPassData?.apiUri,
    reloadOnIncomplete = true
  ) {
  const registrationStep = ref("create");
  const rfError = ref(false);
  const rfMessage = ref("");
 

  const tmplData = ref({
    firstName: user.firstName || 'PCH User',
    tokens: parseInt(noPassBadgeTokens),
    regTokens: parseInt(user.createPasswordTokens || 0),
    level: (user.createPasswordMemberStatus || "bronze").toUpperCase(),
  });

  function cpUpdateText(key, value) {
    tmplData.value[key] = parseInt(value);
  }

  const createPassTxt = createPassText;
  const createStateTxt = createPassTxt?.create;
  const completeStateTxt = createPassTxt?.complete;
  const incompleteStateTxt = createPassTxt?.incomplete;

  const cpMainGreeting = computed(() => {
    switch (registrationStep.value) {
      case "create":
        return tmplHelper(tmplData.value, createStateTxt?.topLine);
      case "complete":
        return tmplHelper(tmplData.value, completeStateTxt?.midLine1);
      case "incomplete":
        return tmplHelper(tmplData.value, incompleteStateTxt?.topLine);
      default:
        break;
    }
  });

  const cpMessage = computed(() => {
    switch (registrationStep.value) {
      case "create":
        return tmplHelper(tmplData.value, createStateTxt?.midLine1);
      case "complete":
        return tmplHelper(tmplData.value, completeStateTxt?.topLine);
      case "incomplete":
        return tmplHelper(tmplData.value, incompleteStateTxt?.midLine1);
      default:
        break;
    }
  });

  const cpDirections = computed(() => {
    switch (registrationStep.value) {
      case "create":
        return tmplHelper(tmplData.value, createStateTxt?.midLine2);
      case "complete":
        return tmplHelper(tmplData.value, completeStateTxt?.bottomLine);
      case "incomplete":
        return tmplHelper(tmplData.value, incompleteStateTxt?.midLine2);
      default:
        break;
    }
  });

  const cpButtonInfo = computed(() => {
    switch (registrationStep.value) {
      case "create":
        return tmplHelper(tmplData.value, createStateTxt?.bottomLine);
      case "complete":
        return tmplHelper(tmplData.value, completeStateTxt?.bottomLine);
      case "incomplete":
        return tmplHelper(tmplData.value, incompleteStateTxt?.bottomLine);
      default:
        break;
    }
  });

  async function createPassword(payload) {
    if (api) {
      const response = await fetch(api, {
        ...POST_CONFIG,
        body: JSON.stringify(payload),
      })
        .then((response) =>
          response.ok ? response.json() : Promise.reject(response)
        )
        .then((json) => {
          if (JSON.stringify(json) !== JSON.stringify({}) && json?.ValidationResponses?.IsValid !== true) {
            return Promise.reject(json);
          } else if(Object.keys(json).length < 1) {
            return true;
          } else {
            return true;
          }
        })
        .catch((err) => {
          console.warn("create pass error:", err);
          if (err.ValidationResponses?.IsValid !== true) {
            rfError.value = true;
            rfMessage.value =
              err?.ValidationResponses?.FieldOrDatabaseValidationResponse?.Responses[0]?.Message;
            return false;
          } else {
            console.error("unknown password response");
          }
        });
      return response;
    } else {
      console.warn("no api for create password");
    }
  }

  const payload = {
    Password: null,
    ConfirmPassword: null
  };

  async function cpSubmit() {
    if (registrationStep.value === "create") {
      const apiSuccess = await createPassword(payload);
      if (apiSuccess) {
        registrationStep.value = "complete";
      }
    }
  }

  function cpYes() {
    if (registrationStep.value === "complete") {
      cpClose();
    } else if (registrationStep.value === "incomplete") {
      registrationStep.value = "create";
    }
  }

  function cpNo() {
    if (registrationStep.value === "complete") {
      cpClose();
    } else if (registrationStep.value === "incomplete") {
      cpClose(reloadOnIncomplete);
    }
  }

  function cpUpdatePayload(field, value) {
    payload[field] = value;
  }

  function cpNoThanks() {
    if ((registrationStep.value = "create")) {
      registrationStep.value = "incomplete";
    }
  }

  return {
    registrationStep,
    rfError,
    rfMessage,
    cpSubmit,
    cpYes,
    cpNo,
    cpUpdatePayload,
    cpNoThanks,
    cpMainGreeting,
    cpDirections,
    cpMessage,
    cpButtonInfo,
    cpUpdateText
  };
}

export { useCreatePassword };